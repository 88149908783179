body,
html {
  margin: 0;
  padding: 0;
  height: 100dvh;
  width: 100vw;
  font-family: sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

:root {
  /* Font sizes */
  --fz-base: 1rem;
  --fz-lg: 1.125rem;
  --fz-xl: 1.25rem;

  /* Colors base */
  --color-main: #407ea4;
  --color-dark-background: #e9eef2;
  --color-app-background: #e8eef1;

  /* Colors elements */
  --color-input-border: rgb(190, 203, 223);
}

input,
label,
button {
  font-size: var(--fz-base);
}
